import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import BASE_URL from './config';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from '@mui/material';
import LoginBG from './assets/loginBG.png';

const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [userdetails, setUserDetails] = useState({
    email: '',
    password: ''
  })

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const UserDetailsChnage = (strippedId, value) => {
    setUserDetails(prevState => ({
      ...prevState,
      [strippedId]: value
    }));
  }

  useEffect(() => {
    const callAbout = async () => {
      try {
        const res = await fetch(`${BASE_URL}/getauthadmin`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include"
        })
        if (res.status === 200) {
          navigate('/admin');
        } else if (res.status === 201) {
          navigate('/user');
        }
        else {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        navigate('/');
      }
    };
    callAbout()
  }, [])

  const UserLogin = async (e) => {
    try {
      e.preventDefault();
      const res = await fetch(`${BASE_URL}/login`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(userdetails)
      })
      const data = await res.json();
      if (res.status === 200) {
        localStorage.setItem("userid", data.UserDetails._id);
        localStorage.setItem("username", data.UserDetails.name);
        localStorage.setItem("usertype", data.UserType);

        if (data.UserType === "User") {
          navigate('/user');
        } else {
          navigate('/admin');
        }
      }
      else {
        alert(data.message);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <div className='' style={{
        height: '100vh',
        width: "100%",
        backgroundImage: `url(${LoginBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: "no-repeat",
      }}>
        <div className="d-flex align-items-center justify-content-center h-100 ">
          <div className='d-flex flex-column gap-4 p-5 rounded-5' style={{ width: '500px', backgroundColor: '#de823f' }}>
            <div className='d-flex justify-content-center text-white fw-bolder'><h1>AMS</h1></div>
            <form onSubmit={UserLogin} className='d-flex flex-column gap-4 align-items-center'>
              <TextField sx={{

                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                    borderRadius: '0px',
                    borderWidth: '2px'
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },

                '& .MuiInputLabel-root': {
                  color: 'white',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'white',
                }
              }} label="Username" variant='outlined' size='medium' className='w-100' color='primary' autoComplete='off' value={userdetails.email} onChange={(e) => UserDetailsChnage("email", e.target.value)} />
              <TextField
                type={showPassword ? 'text' : 'password'}
                label="Password"
                variant="outlined"
                size="medium"
                className="w-100"
                sx={{

                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white',
                      borderRadius: '0px',
                      borderWidth: '2px'
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                  },

                  '& .MuiInputLabel-root': {
                    color: 'white',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: 'white',
                  }
                }}
                value={userdetails.password} onChange={(e) => UserDetailsChnage("password", e.target.value)}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                        edge="end"
                        sx={{ color: "white" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button type='submit' className='w-25 bg-white fs-6 fw-bolder rounded-3' sx={{ color: "#9b3054" }} variant="contained">Log in</Button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login