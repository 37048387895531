import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditAccordion from './EditAccordion';
import { AllFroms } from '../../store/AppContext';
import BASE_URL from '../../config';

const CustomFormEdit = () => {
    let { customid } = useParams();
    const { customineFrom, setCustomineFrom, responsible, setResponsible, usertype, name, userId, responsibleUsers } = useContext(AllFroms);

    const [auditname, setAuditName] = useState('');
    const [templatename, setTemplatename] = useState('');
    const [open, setOpen] = useState(false);

    const [requestDetails, setRequestDetails] = useState({
        targetDate: '',
        rowId: '',
        accordionindex: '',
        subheadingIndex: '',
        headindex: '',
    });



    useEffect(() => {
        async function GetCustomTemplate() {
            const response = await fetch(`${BASE_URL}/getsinglecustomaudit`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ customid, userId, usertype })
            });
            var formData = await response.json();
            console.log(formData);
            if (response.ok) {
                // var indexArray = [];

                // // Iterate through the keys of the object
                // Object.keys(formData.customaudit.indexDetails).forEach(key => {
                //     // Push each value (array of objects) into the new array
                //     indexArray.push(...formData.customaudit.indexDetails[key]);
                // });
                // setIndexDetails(indexArray);
                setCustomineFrom(formData.customaudit.template_detail);
                setAuditName(formData.customaudit.auditname);
                setTemplatename(formData.customaudit.templatename);
            } else {
                console.error('Error:', response.statusText);
            }
        }
        GetCustomTemplate();
    }, [customid, setCustomineFrom, userId, usertype]);

    const handleTargetDateChange = (date, rowId, accordionindex, subheadingIndex, headindex) => {
        setRequestDetails({
            targetDate: date,
            rowId: rowId,
            accordionindex: accordionindex,
            subheadingIndex: subheadingIndex,
            headindex: headindex
        })
        setOpen(true);
    }
    const handleResponsibilityChange = (accordionindex, subheadingIndex) => {
        setRequestDetails({
            accordionindex: accordionindex,
            subheadingIndex: subheadingIndex,
            headindex: 0
        })
        setResponsible(true)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleConfirm = async () => {
        setOpen(false);
        try {
            const requestData = {
                userName: name,
                auditId: customid,
                requestDetails
            };

            console.log(requestData);
            const url = responsible ? `${BASE_URL}/requestresponsibilitychange` : `${BASE_URL}/requestdatechange`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            });
            const resultdata = await res.json();
            alert(resultdata.message);
        } catch (error) {
            console.error('Request failed:', error);
        }
    }


    const saveFormDetails = async (type) => {
        try {
            let requestData = null;
            if (type === "submit") {
                requestData = {
                    formData: customineFrom,
                    auditname: auditname,
                    templatename: templatename,
                    isSubmit: true,
                    responsibleUsers: responsibleUsers
                };
            } else {
                requestData = {
                    userId,
                    formData: customineFrom,
                    auditname: auditname,
                    templatename: templatename,
                    responsibleUsers: responsibleUsers
                };
            }

            const url = usertype === "User" ?
                `${BASE_URL}/editcustomformuser/${customid}` :
                `${BASE_URL}/editcustomform/${customid}`;

            const res = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestData)
            });
            const resultdata = await res.json();
            alert(resultdata.message);
            window.location.href = "/user";
        } catch (error) {
            console.error('Upload failed:', error);
        }
    };

    return (
        <div>
            <div className='d-flex gap-3 mb-2'>
                <TextField label={"Audit Name"} variant='outlined' size='medium' className='w-100' value={auditname} onChange={(e) => setAuditName(e.target.value)} />
                <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("save")}>Save</Button>
                {usertype === "HOD" ? <Button variant="contained" style={{ width: '150px' }} onClick={() => saveFormDetails("submit")}>Submit</Button> : null}
            </div>
            <div className='pb-2 fw-light'>Audit created By : {name} ({usertype})</div>
            {customineFrom && customineFrom.map((key, value) => (
                <EditAccordion
                    key={key.header_name}
                    title={key.header_name}
                    content={key.header_subheading}
                    accordionindex={value}
                    onTargetDateChange={handleTargetDateChange}
                    onResponsibilityChange={handleResponsibilityChange}
                />
            ))}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{responsible ? "Remove Responsibility" : "Target Change Request"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {responsible ? "Send request to remove the responsible field" :

                            "Do you want to send a target change request to the AUDITOR?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleConfirm} autoFocus>SEND</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CustomFormEdit;


