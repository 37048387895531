import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BASE_URL from '../config';
import Profile from '../assets/profile.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

const adminPagesLinks = {
  'Templates': '',
  'Create Template': '/createtemplate',
  'Create User': '/createuser',
  'All Users': '/usertable'
};

const TopNavbar = ({ userdetails }) => {

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (userdetails.usertype === 'HOD') {
      fetchRequests();
    }
  }, [userdetails.usertype]);

  const fetchRequests = async () => {
    try {
      const response = await fetch(`${BASE_URL}/getapprovalrequests`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json"
        }
      });
      const result = await response.json();
      console.log(result);
      if (response.ok) {
        setNotifications(result.requests);
      } else {
        console.error('Error:', result.message);
      }
    } catch (error) {
      console.error('Fetch requests failed:', error);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    const res = await fetch(`${BASE_URL}/logout`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    });
    const resultdata = await res.json();
    localStorage.clear();
    alert(resultdata.message);
    window.location.href = "/";
  }

  const handleNavigation = async (page) => {
    var url = '/admin' + page;
    window.location.href = url;
  }

  const handleOpenNotificationsModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleApprove = async (requestId) => {
    try {
      const response = await fetch(`${BASE_URL}/approveRequest`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ requestId })
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        fetchRequests();
      } else {
        console.error('Error:', result.message);
      }
    } catch (error) {
      console.error('Approve request failed:', error);
    }
    handleCloseModal();
  };

  const handleReject = async (requestId) => {
    try {
      const response = await fetch(`${BASE_URL}/rejectRequest`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ requestId })
      });
      const result = await response.json();
      if (response.ok) {
        alert(result.message);
        fetchRequests();
      } else {
        console.error('Error:', result.message);
      }
    } catch (error) {
      console.error('Reject request failed:', error);
    }
    handleCloseModal();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }} className='position-sticky z-3 top-0'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="overline"
            noWrap
            component="a"
            href="/user"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontSize: 25,
              fontWeight: 900,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              AUDIT MANAGEMENT SYSTEM
            </Box>
          </Typography>

          {userdetails.admin ?
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {Object.entries(adminPagesLinks).map(([page, link]) => (
                  <MenuItem key={page} onClick={() => handleNavigation(link)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            : null}

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            AUDIT MANAGEMENT SYSTEM
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className='justify-content-end mx-3'>
            {userdetails.admin &&
              Object.entries(adminPagesLinks).map(([page, link]) => (
                <Button
                  key={page}
                  onClick={() => handleNavigation(link)}
                  sx={{ my: 2, color: 'black', display: 'block' }} className='mx-2'>
                  {page}
                </Button>
              ))
            }
          </Box>

          {userdetails.usertype === 'HOD' && (
            <IconButton color="inherit" onClick={handleOpenNotificationsModal}>
              <NotificationsIcon />
              {notifications.length > 0 && (
                <span className="notification-badge">{notifications.length}</span>
              )}
            </IconButton>
          )}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings" className='d-flex'>
              <div className='d-flex flex-column'>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={userdetails.name} src={Profile} sx={{ width: 50, height: 50 }} />
                </IconButton>
                <div className='d-flex justify-content-center'>
                  <span>{userdetails.usertype}</span>
                </div>
              </div>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>



      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Pending Requests</DialogTitle>
        <DialogContent>
          {notifications.length > 0 ? (
            <Grid container spacing={2}>
              {notifications.map((request) => (
                <Grid item xs={12} key={request._id}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {request.newTargetDate ? `Target change request from ${request.userName}` : `Delete responsibility request from ${request.userName}`}
                      </Typography>
                      <Typography color="textSecondary">
                        {`Audit: ${request.auditId.auditname}`}
                      </Typography>
                      <Typography color="textSecondary">
                        {`Template: ${request.auditId.templatename}`}
                      </Typography>
                      <Typography color="textSecondary">
                        {request.newTargetDate && `New Target Date: ${request.newTargetDate}`}
                      </Typography>
                      <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button onClick={() => handleReject(request._id)} color="secondary" variant="contained" sx={{ mr: 1 }}>
                          Reject
                        </Button>
                        <Button onClick={() => handleApprove(request._id)} color="primary" variant="contained">
                          Approve
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <DialogContentText>No pending requests</DialogContentText>
          )}
        </DialogContent>
      </Dialog>

    </AppBar>
  )
}

export default TopNavbar;
