import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import BASE_URL from '../config';
import { AllFroms } from '../store/AppContext';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const Table = () => {
  const [audit_name, setAuditName] = useState('');
  const [formType, setFormType] = useState('form');
  const [auditData, setAuditData] = useState([]);
  const [alltemplates, setAlltemplates] = useState([]);
  const [customaudits, setCustomaudits] = useState([]);

  const { usertype, userId } = useContext(AllFroms);

  const formMapping = {
    form: "F 11",
    firstfive: "First Five",
    timecheck: "Time Check",
    dailyprocess: "Daily Process",
    dailyline: "Daily Line"
  };


  useEffect(() => {
    async function fetchData() {
      const currentUser = localStorage.getItem('usertype')
      const currentUserId = localStorage.getItem('userid')

      if (currentUser === "User") {
        const myres = await fetch(`${BASE_URL}/getmyaudit`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ "userid": currentUserId })
        });
        const myresultdata = await myres.json();
        setCustomaudits(myresultdata);
      }
      else {
        const res = await fetch(`${BASE_URL}/getallaudit`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });
        const resultdata = await res.json();
        setAuditData(resultdata);

        const res2 = await fetch(`${BASE_URL}/getallcustomaudit`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });
        const resultdata2 = await res2.json();
        setCustomaudits(resultdata2.allcustomaudits);
      }


      const response = await fetch(`${BASE_URL}/getalltemplates`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      const responsedata = await response.json();
      setAlltemplates(responsedata.alltemplates);
    }
    fetchData();
  }, [])

  const CreateNewAudit = async () => {
    try {
      if (usertype === 'User') {
        alert("You are not Authorised to Create Audit");
        return;
      }
      if (formType.includes("custom_")) {
        if (audit_name === '') {
          alert("Audit Name is Required");
          return;
        }

        var templateid = formType.replace("custom_", "");

        window.location.href = `/user/customfrom/${templateid}?auditname=${audit_name}`;
      } else {
        if (audit_name === '') {
          alert("Audit Name is Required");
          return;
        }
        const res = await fetch(`${BASE_URL}/createaudit`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "audit_name": audit_name,
            "FormType": formType
          }),
          credentials: "include"
        });
        const resultdata = await res.json();
        alert(resultdata.message);
        var auditID = resultdata.AuditDetails._id;
        window.location.href = `/user/${formType}/${auditID}`;
      }

    } catch (error) {
      console.error('Create Audit failed:', error);
    }
  }

  const DeleteAudit = async (auditID) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this audit?');

    if (!isConfirmed) {
      return;
    }
    try {
      const res = await fetch(`${BASE_URL}/deleteaudit/${auditID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!res.ok) {
        throw new Error('Failed to delete Audit');
      }
      const data = await res.json();
      alert(data.message);
      window.location.reload();
    } catch (error) {
      console.error('Error deleting Audit:', error);
    }
  };

  const DeleteCustomAudit = async (auditID) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this audit?');

    if (!isConfirmed) {
      return;
    }
    try {
      const res = await fetch(`${BASE_URL}/deletecustomaudit/${auditID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!res.ok) {
        throw new Error('Failed to delete Audit');
      }
      const data = await res.json();
      alert(data.message);
      window.location.reload();
    } catch (error) {
      console.error('Error deleting Audit:', error);
    }
  };

  return (
    <div className='my-3'>
      {usertype === 'HOD' ?
        <div className='d-flex gap-3 mb-2 py-3'>
          <TextField sx={{

            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'black',
                borderRadius: '0px',
                borderWidth: '2px'
              },
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },

            '& .MuiInputLabel-root': {
              color: 'black',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black',
            }
          }} label={"Audit Name"} variant='outlined' size='medium' className='w-100' value={audit_name} onChange={(e) => setAuditName(e.target.value)} />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" sx={{
              color: 'black',
              '&.Mui-focused': {
                color: 'black',
              },
            }}>Select Form</InputLabel>
            <Select
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                  borderWidth: '2px',
                  borderRadius: '0px'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formType}
              label="Select Form"
              onChange={(e) => setFormType(e.target.value)}
            >
              {Object.entries(formMapping).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
              {alltemplates.map((key, index) => (
                <MenuItem key={index} value={'custom_' + key._id}>
                  {key.template_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" style={{ width: '350px', backgroundColor: '#de823f' }} onClick={CreateNewAudit} className='fs-6 fw-bolder rounded-3'>Create Audit</Button>
        </div> : null}
      <div className='d-flex flex-wrap justify-content-center'>
        {customaudits.map((row, rowindex) => (

          <div key={rowindex} className='mx-5 col-6 col-sm-6 col-md-4 col-lg-2 mb-5'>
            <Card sx={{
              transition: 'box-shadow 0.3s',
              '&:hover': {
                boxShadow: '2px 2px 20px rgba(171,41,66,255)'
              },
              borderColor: '#b32643', borderWidth: 4, borderStyle: 'solid'

            }} className='d-flex justify-content-center rounded-5'>

              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>

                <CardContent sx={{ flex: '1 0 auto', textAlign: 'center' }} className='pb-0' >
                  <Typography component="div" variant="button" className='border border-dark rounded-3' sx={{ backgroundColor: rowindex % 2 === 0 ? '#c46623' : "#1976d2" }}>
                    {row.auditname}
                  </Typography>
                </CardContent>


                <CardContent sx={{ flex: '1 0 auto', textAlign: 'center' }} className='p-0 pb-2'>
                  <Typography component="div" variant="overline" className='fs-5 d-flex align-items-center'>
                    <ChevronRightIcon sx={{ color: rowindex % 2 === 0 ? '#c46623' : "#1976d2" }} fontSize='large' />
                    {row.templatename}
                  </Typography>

                  <Typography component="div" variant="overline" className='fs-5 d-flex align-items-center gap-2 px-2'>
                    {row.isSubmit ? <CheckCircleIcon sx={{ color: rowindex % 2 === 0 ? '#c46623' : "#1976d2" }} fontSize='medium' /> :
                      <AccessTimeIcon sx={{ color: rowindex % 2 === 0 ? '#c46623' : "#1976d2" }} fontSize='medium' />}
                    {row.isSubmit ? "COMPLETED" : "PENDING"}
                  </Typography>
                </CardContent>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center', backgroundColor: rowindex % 2 === 0 ? '#1976d2' : "#c46623", color: "white" }}>
                  <IconButton component={Link} to={`/user/customfromview/${row._id}`} aria-label="edit" >
                    <VisibilityIcon fontSize='medium' />
                  </IconButton>

                  {!row.isSubmit && <IconButton component={Link} to={`/user/customfromedit/${row._id}?edit=true`} aria-label="edit" >
                    <AppRegistrationIcon fontSize='medium' />
                  </IconButton>}

                  <IconButton aria-label="delete" onClick={() => DeleteCustomAudit(row._id)} >
                    <DeleteIcon fontSize='medium' />
                  </IconButton>
                </Box>


              </Box>

            </Card>
          </div>
        ))
        }
        {auditData.map((row, rowindex) => (

          <div key={rowindex} className='mx-5 col-6 col-sm-6 col-md-4 col-lg-2 mb-5'>
            <Card sx={{
              transition: 'box-shadow 0.3s',
              '&:hover': {
                boxShadow: '2px 2px 20px rgba(171,41,66,255)'
              },
              borderColor: '#b32643', borderWidth: 4, borderStyle: 'solid'

            }} className='d-flex justify-content-center rounded-5'>

              <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>

                <CardContent sx={{ flex: '1 0 auto', textAlign: 'center' }} className='pb-0' >
                  <Typography component="div" variant="button" className='border border-dark rounded-3' sx={{ backgroundColor: rowindex % 2 === 0 ? '#c46623' : "#1976d2" }}>
                    {row.audit_name}
                  </Typography>
                </CardContent>


                <CardContent sx={{ flex: '1 0 auto', textAlign: 'center' }} className='p-0 pb-2'>
                  <Typography component="div" variant="overline" className='fs-5 d-flex align-items-center'>
                    <ChevronRightIcon sx={{ color: rowindex % 2 === 0 ? '#c46623' : "#1976d2" }} fontSize='large' />
                    {row.form_name}
                  </Typography>

                  <Typography component="div" variant="overline" className='fs-5 d-flex align-items-center gap-1 px-1'>
                    {row.isSubmit ? <CheckCircleIcon sx={{ color: rowindex % 2 === 0 ? '#c46623' : "#1976d2" }} fontSize='medium' /> :
                      <AccessTimeIcon sx={{ color: rowindex % 2 === 0 ? '#c46623' : "#1976d2" }} fontSize='medium' />}
                    {row.isSubmit ? "COMPLETED" : "PENDING"}
                  </Typography>
                </CardContent>

                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center', backgroundColor: rowindex % 2 === 0 ? '#1976d2' : "#c46623", color: "white" }}>
                  {row.other_keys[0] ?
                    <IconButton component={Link} to={`/user/${row.form_name}view/${row._id}`} aria-label="edit" >
                      <VisibilityIcon fontSize='medium' />
                    </IconButton> :
                    <IconButton component={Link} to={`/user/${row.form_name}/${row._id}`} aria-label="edit" >
                      <ArrowRightAltIcon fontSize='medium' />
                    </IconButton>
                  }

                  {row.other_keys[0] && !row.isSubmit && <IconButton component={Link} to={`/user/${row.form_name}/${row._id}?edit=true`} aria-label="edit" >
                    <AppRegistrationIcon fontSize='medium' />
                  </IconButton>}

                  <IconButton aria-label="delete" onClick={() => DeleteAudit(row._id)} >
                    <DeleteIcon fontSize='medium' />
                  </IconButton>
                </Box>


              </Box>

            </Card>
          </div>
        ))
        }
      </div>
    </div>
  )
}

export default Table



