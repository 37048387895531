import React, { useEffect, useContext, useState } from 'react'
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import BASE_URL from './config';
import { AllFroms } from './store/AppContext';
import TopNavbar from './components/TopNavbar';
import UserBg from './assets/bgDown.png'

const User = () => {
  const navigate = useNavigate();

  const { setUserType  } = useContext(AllFroms);

  const [userdetails, setUserdetails] = useState({});

  useEffect(() => {
    const callAbout = async () => {
      try {
        const res = await fetch(`${BASE_URL}/getauthuser`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include"
        })
        if (res.status === 200) {
          var response = await res.json();
          setUserType(response.UserDetails.usertype);
          setUserdetails(response.UserDetails);         
        }
        else {
          const error = new Error(res.error);
          throw error;
        }
      } catch (err) {
        console.log(err);
        navigate('/');
      }
    };
    callAbout()
  }, [setUserType, navigate])
  return (
    <div style={{
      height: '100vh',
      width: '100%',
      backgroundImage: `url(${UserBg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      overflow: 'auto',
    }}>
      <TopNavbar userdetails={userdetails} />
      <div className="container mt-3" >
        {/* <div className="d-flex justify-content-between my-3 align-items-center">
            <img src='/images/logo.jpg' alt='Logo' style={{width:'100px'}}/>
            <h3>Kunstocom India Ltd</h3>
        </div> */}
        {/* <div className=""> */}
        <Outlet />
        {/* </div> */}
      </div>
    </div>
  )
}

export default User